export default [
  {
    header: 'Stats',
  },
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Analytics',
        route: 'home',
      },
    ],
  },
 
  {
    header: 'Apps',
  },
  {
    title: 'SMS',
    icon: 'MessageSquareIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Request',
        route: 'request',
      },
      {
        title: 'Inbox',
        route: 'apps-inbox',
      },
      {
        title: 'Bulk SMS',
        children: [
          {
            title: 'Outbox',
            route: 'bulk-message',
          },
          {
            title: 'Analytic',
            route: 'sms-analytic',
          },
          {
            title: 'Message',
            route: 'bulk-message-list',
          },
          {
            title: 'Blacklist',
            route: 'blacklist-view',
          },
          
        ],
      },
      {
        title: 'Premium',
        children: [
          {
            title: 'Outbox',
            route: 'premium-outbox',
          },
          {
            title: 'Sub Product',
            route: 'premium-product',
          },
          {
            title: 'Analytic',
            route: 'premium-analytic',
          },
        ],
      },
      {
        title: 'Shortcode',
        children: [
          {
            title: 'My Shortcode',
            route: 'shortcode',
          },
        ],
      },
      {
        title: 'Aphalnumeric',
        children: [
          {
            title: 'Alphanumeric',
            route: 'alphanumerics',
          },
        ],
      },
      {
        title: 'Keywords',
        children: [
          {
            title: 'My Keywords',
            route: 'keyword',
          },
          {
            title: 'Request',
            route: 'keyword-apply',
          },
        ],
      },
      {
        title: 'SMPP Accounts',
        route: 'smpp',
      },
      {
        title: 'SMS Callback',
        children: [
          {
            title: 'Delivery URL',
            route: 'callback-dlr',
          },
          {
            title: 'Incoming Message',
            route: 'callback-incoming',
          },
          {
            title: 'Sub Notification',
            route: 'callback-subscription',
          },
        ],
      },
    ],
  },
  {
    title: 'USSD',
    icon: 'CheckSquareIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Services Code',
        route: 'second-page',
      },
      {
        title: 'Request',
        route: 'second-page',
      },
      {
        title: 'Session',
        route: 'second-page',
      },
      {
        title: 'Analytics',
        route: 'second-page',
      },
      {
        title: 'Callback URLs',
        route: 'second-page',
      },
    ]
  },
  {
    title: 'Airtime',
    icon: 'FileIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Transation',
        route: 'second-page',
      },
      {
        title: 'Analytics',
        route: 'second-page',
      },
    ]
  },
  {
    title: 'Payments',
    icon: 'PieChartIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Product',
        route: 'second-page',
      },
      {
        title: 'Request',
        route: 'second-page',
      },
      {
        title: 'Transaction',
        route: 'second-page',
      },
      {
        title: 'Analytics',
        route: 'second-page',
      },
    ]
  },
  {
    title: 'Billing',
    icon: 'ShoppingCartIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Payment Method',
        route: 'second-page',
      },
      {
        title: 'Top ups',
        route: 'second-page',
      },
      {
        title: 'Expenditure',
        route: 'second-page',
      },
    ]
  },
  {
    header: 'Settings',
  },
  {
    title: 'Services Request',
    icon: 'FileTextIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'My Request',
        route: 'view-request',
      },
      {
        title: 'Apply Request',
        route: 'request',
      },
    ],
  },
  {
    title: 'Developers',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'API key',
        route: 'dev-api',
      },
      {
        title: 'Balance Alert',
        route: 'balance-alert',
      },
    ],
  },
]
